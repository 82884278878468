import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { getUrlParams } from '../../../helpers/location';
import { isAvailablewithTimeSlot } from '../../../helpers/date';
import { setOrderInfo, setAppointmentSlot } from '../../../actions/orderInfo';
import * as Api from '../../../apis';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import SchoolOutlined from '@material-ui/icons/SchoolOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import TimeSlotPicker from '../../../components/TimeSlotPicker';
import Avatar from '../../../components/Avatar';
import Layout from '../../../components/v2/Layout';
import Button from '../../../components/v2/Button';
import DoctorBadge from '../../../components/v2/DoctorBadge';

const useStyles = makeStyles(theme => ({
  chip: {
    height: 20,
    fontSize: '0.9em',
    marginRight: 4
  },
  icon: {
    fontSize: '100px'
  },
  nextButton: {
    width: 150
  },
  description: {
    color: 'black',
  },
  remark: {
    color: 'black',
    background: '#dbe7f3',
    padding: 10,
    borderRadius: 4
  },
  calendarContainer: {
    '& > * + *': {
      marginTop: theme.spacing(1)
    }
  }
}));


export default ({ location }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const query = getUrlParams(location.search);
  const { consultantId } = query;
  const categoryById = useSelector(state => state.appointment.categoryById);
  const consultant = useSelector(state => state.appointment.consultantById[consultantId]);
  const orderInfo = useSelector(state => state.orderInfo);
  const {
    cardId,
    partnerId,
    addOnBenefit,
    consultantListAvailableOnly
  } = useSelector(state => state.partners);
  const [dialog, setDialog] = useState(false);

  useEffect(() => {
    if (consultant) {
      dispatch(setOrderInfo({ consultantId: consultant.id }));
    }
  }, [consultant]);

  const fetchTimeSlot = async () => {
    let dateSlots = {};
    const data = await Api.getTimeSlots(consultantId, false, cardId, partnerId, true);
    const timeSlots = data.results;
    timeSlots.forEach(timeSlot => {
      const slot = dateSlots[timeSlot.date] || [];
      slot.push(timeSlot);
      dateSlots[timeSlot.date] = slot;
    })
    return dateSlots;
  }

  function removeSeconds(val) {
    if (!val) return val;
    return val.split(":").slice(0, 2).join(":");
  }

  const confirmAppointment = () => {
    if (!orderInfo.slotTimeId) {
      alert('กรุณากรอกข้อมูลให้ครบ');
      return;
    }
    setDialog(false);
    navigate(`/partners/consult/personalInfo`);
  }

  const consultNow = async () => {
    window.logEvent('select_content', { content_type: 'consultant', item_id: consultant.id + '', item_name: consultant.display_name });

    if (consultant.is_available) {
      dispatch(setOrderInfo({ consultNow: true }));
    } else if (consultant.fastest_timeslot_datetime) {
      const slots = await fetchTimeSlot();
      const date = (consultant.fastest_timeslot_datetime).split("T")[0]; // find slot fastest from all slot
      const time = ((consultant.fastest_timeslot_datetime).split("T")[1]).split("+")[0];
      const availableTimeSlots = slots[date];
      const slot = availableTimeSlots.filter(val => val?.start_time === removeSeconds(time));
      if (slot.length > 0) {
        dispatch(setAppointmentSlot(consultant.id, slot[0]));
      }
    }
    navigate(`/partners/consult/personalInfo`);
  }

  const onClickBooking = () => {
    window.logEvent('select_content', { content_type: 'button', item_id: 'check_availability', consultant_id: consultantId + '' });

    setDialog(true);
  }

  const showBookingButton = (() => {
    return !consultantListAvailableOnly
  })();

  if (!consultant) return false;
  
  let isAvailable = consultant.is_available;
  const fastestSlot = consultant.fastest_timeslot_datetime;
  if (!isAvailable) {
    if (fastestSlot !== null) {
      isAvailable = isAvailablewithTimeSlot(fastestSlot);
    }
  }

  const consultNowButtonText = 
    addOnBenefit && addOnBenefit == 'nhso_benefit'
      ? "นัดหมาย" : "ปรึกษาทันที";

  return (
    <Layout bg="bg2" title="ทำการนัดหมาย" loading={!consultant}>
      <TimeSlotPicker
        noNote
        open={dialog}
        onClose={() => setDialog(false)}
        onConfirm={confirmAppointment}
        fetchTimeSlot={fetchTimeSlot}
      />
      <Box display="flex" alignItems="center" flexDirection="column">
        <Avatar online={isAvailable} width={110} height={110} src={consultant?.image} />
        <Box mt={1} textAlign="center">
          <Typography variant="h6" component="h1" color="textPrimary">{consultant.display_name}</Typography>
          {/* <Typography variant="subtitle1" component="p" color="textSecondary">อัตราค่าปรึกษา {consultant.price} บาท / ครั้ง</Typography> */}
        </Box>
        <Box mt={1}>
          {
            consultant.categories.map((cat, index) => {
              return (
                <DoctorBadge key={index}>{cat.name}</DoctorBadge>
              )
            })
          }
        </Box>
      </Box>
      <Box mt={2}>
        <Typography variant="h6" component="h1" color="textPrimary">ข้อมูลแพทย์</Typography>
        <Box ml={1}>
          <Box display="flex" alignItems="center">
            <LocationOnOutlinedIcon color="primary" fontSize="inherit"/><Typography style={{ marginLeft: 4 }} variant="subtitle1" component="p" color="textSecondary">{consultant.affiliation || "-"}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <SchoolOutlined color="primary" fontSize="inherit"/><Typography style={{ marginLeft: 4 }} variant="subtitle1" component="p" color="textSecondary">{consultant.education || "-"}</Typography>
          </Box>
        </Box>
      </Box>
      <Box mt={2}>
        <Typography variant="h6" component="h1" color="textPrimary">ประวัติ</Typography>
        <Box ml={1}>
          <Typography  variant="subtitle1" component="p" color="textSecondary">{consultant.remark || "-"}</Typography>
        </Box>
      </Box>
      <Box my={2}>
        <Typography variant="h6" component="h1" color="textPrimary">ความเชี่ยวชาญ</Typography>
        <Box ml={1}>
          <Typography  variant="subtitle1" component="p" color="textSecondary">{consultant.description || "-"}</Typography>
        </Box>
      </Box>
      <Divider />
      <Box height="75px" display="flex" justifyContent="center" alignItems="center">
        { showBookingButton &&
          <Box flex={1}>
            <Button fullWidth variant="outlined" size="large" onClick={onClickBooking}>จองวันที่และเวลา</Button>
          </Box>
        }
        {
          isAvailable && (
            <Box flex={1} marginLeft={1}>
              <Button fullWidth size="large" onClick={consultNow}>{consultNowButtonText}</Button>
            </Box>
          )
        }
      </Box>
    </Layout>
  );
}
